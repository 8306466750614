import React from "react";
// import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import styles from "./InfoTable.module.css";
import { etc, btc, bnb, matic, avax, usdc } from "../../../images/image";
import SingleRow from "./SingleRow";

const InfoTable = ({ enableTransfer }) => {
  const data = [
    {
      assets: { logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png", currencyName: "Tether USDT" },
      symbol: "USDT",
      balance: 0.0,
      usdBalance: 0.0,
      price: 0.0,
      change24h: 0.5,
      chartData: {
        data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 250, 300, 280],
        color: "#FCD435",
      },
      claim: "ss",
      withdraw: true,
      depositAllowed: true,
      depositlocked: false,


    },
    {
      assets: { logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png", currencyName: "BNB Smart Chain" },
      balance: 0.0154,
      usdBalance: 437.7,
      price: 28452.5,
      change24h: 0.8,
      value: 134932.24,
      allocation: 21.34,
      chartData: {
        data: [150, 200, 180, 250, 300, 280, 150, 200, 180, 250, 300, 280],
        color: "#FCD435",
      },
      claim: "",
      withdraw: true,
      // withdrawlocked: true,
      depositlocked: false,
      depositAllowed: true,

    },
    {
      assets: { logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png", currencyName: "ETH" },
      balance: 0.0154,
      usdBalance: 437.7,
      price: 28452.5,
      change24h: 0.8,
      value: 134932.24,
      allocation: 21.34,
      chartData: {
        data: [150, 200, 180, 250, 300, 280, 150, 200, 180, 250, 300, 280],
        color: "#FCD435",
      },
      claim: "",
      withdraw: true,
      // withdrawlocked: true,
      depositlocked: false,
      depositAllowed: true,

    },
    // {
    //   assets: { logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/23081.png", currencyName: "UNS" },
    //   balance: 0.0154,
    //   usdBalance: 437.7,
    //   price: 28452.5,
    //   change24h: 0.8,
    //   value: 134932.24,
    //   allocation: 21.34,
    //   chartData: {
    //     data: [150, 200, 180, 250, 300, 280, 150, 200, 180, 250, 300, 280],
    //     color: "#FCD435",
    //   },
    //   claim: "",
    //   withdraw: true,
    //   // withdrawlocked: true,
    //   depositlocked: true,
    //   depositAllowed: true,

    // },

  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.row}>
              <th
                className={[styles.heading, styles.textAlignCenter].join(" ")}
              >
                #
              </th>
              <th className={[styles.heading, styles.textAlignLeft].join(" ")}>
                Assets
              </th>
              {/* <th className={styles.heading}>Balance</th> */}
              {/* <th className={styles.heading}>Price</th> */}
              {/* <th className={styles.heading}>24h</th> */}
              {/* <th className={styles.heading}>7d Price Change</th> */}
              <th className={styles.heading}>Action</th>
            </tr>
            {data.map((el, index) => (
              <SingleRow {...el} key={index} index={index} enableTransfer={enableTransfer} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InfoTable;
